<template>
  <div class="iframe-container-box">
    <rs-form
      label-width="80px"
      size="small"
      ref="form1"
      label-position="right"
      :model="formDataSelect"
    >
      <rs-row>
        <rs-col :span="6">
          <rs-form-item label="模块">
            <rs-select v-model="module" clearable placeholder="请选择模块">
              <rs-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item"
              ></rs-option>
            </rs-select>
          </rs-form-item>
        </rs-col>
        <rs-col :span="6">
          <rs-form-item label="状态">
            <rs-select v-model="status" clearable placeholder="请选择状态">
              <rs-option
                v-for="item in statusOptions"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></rs-option>
            </rs-select>
          </rs-form-item>
        </rs-col>
        <rs-col :span="10">
          <rs-form-item label="时间">
            <rs-date-picker
              style="width: 100%"
              v-model="createTimeStart"
              type="daterange"
              align="right"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></rs-date-picker>
          </rs-form-item>
        </rs-col>
        <rs-col :span="6">
          <rs-form-item
            label
            class="marginBottom0"
            label-width="0"
            style="margin-left: 10px"
          >
            <rs-button size="small" type="primary" @click="search" :loading="loading"
              >查询</rs-button
            >
            <rs-button type="text" @click="clean">重置</rs-button>
          </rs-form-item>
        </rs-col>
      </rs-row>
    </rs-form>
    <div class="table-box">
      <rs-table
        class="my-table"
        border
        tooltip-effect="light"
        highlight-current-row
        height="calc(100vh - 180px)"
        :data="tableData"
        style="width: 100%"
      >
        <rs-table-column label="序号" type="index" width="70"></rs-table-column>
        <rs-table-column
          prop="subModule"
          label="文件名称"
          align="center"
        ></rs-table-column>
        <rs-table-column prop="module" label="模块" align="center"></rs-table-column>
        <rs-table-column
          prop="status"
          label="状态"
          :formatter="formaterPostionStatus"
          align="center"
        ></rs-table-column>
        <rs-table-column
          prop="createTimeStr"
          label="创建时间"
          align="center"
        ></rs-table-column>
        <rs-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <rs-button
              @click="handleClick(scope.row)"
              v-if="scope.row.url"
              type="text"
              size="small"
              >下载</rs-button
            >
          </template>
        </rs-table-column>
      </rs-table>
      <div class="align_right marginTo10">
        <rs-pagination
          class="pagination marginTo25"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="prev, pager, next,sizes, jumper"
          :total="total"
          background
        ></rs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
export default {
  name: "downLoad",
  data() {
    return {
      options: [],
      statusOptions: [
        {
          key: 0,
          value: "等待中",
          // },{
          //   key:1,
          //   value:'生成中'
        },
        {
          key: 2,
          value: "生成成功",
        },
        {
          key: 3,
          value: "生成失败",
          // },{
          //   key:4,
          //   value:'生成超时'
        },
      ],
      createTimeStart: "",
      module: "",
      status: "",
      tableData: [],
      height: "",
      currentPage: 1,
      pageSize: 20,
      total: 0,
      formDataSelect: {},
      loading: false,
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    getOption() {
      Api.ExcelModule().then((res) => {
        this.options = res.data.data;
      });
    },
    getList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        module: this.module,
        status: this.status,
      };
      if (this.createTimeStart && this.createTimeStart.length > 0) {
        params["createTimeStart"] = this.createTimeStart[0];
        params["createTimeEnd"] = this.createTimeStart[1];
      }
      Api.ExcelDownload(params)
        .then((res) => {
          this.total = res.data.data.totalRecords;
          this.tableData = res.data.data.records;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    handleClick(row) {
      window.open(`${row.url}?filename=${row.subModule}`);
      //top.location.href = `${row.url}?filename=${row.subModule}`
    },
    formaterPostionStatus(val) {
      switch (val.status) {
        case 0:
          return "等待中";
          break;
        case 1:
          return "生成中";
          break;
        case 2:
          return "生成成功";
          break;
        case 3:
          return "生成失败";
          break;
        case 4:
          return "生成超时";
          break;
      }
    },
    search() {
      this.currentPage = 1;
      this.loading = true;
      this.getList();
    },
    clean() {
      this.module = "";
      this.status = "";
      this.createTimeStart = "";
    },
    // formaterDate(val){
    //     let date =  new Date(val.createTime)
    //     return utils.dateFormatter(date,'yyyy-MM-dd hh:mm:ss')
    //     // return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    //
    // }
  },
  created() {},
  mounted() {
    this.getOption();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.search_button {
  margin-top: 20px;
}
.pagination {
  text-align: right;
}
.my_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  .lable {
    width: 100px;
  }
}
/deep/.search_block .rs-date-editor {
  width: 100% !important;
}
</style>
<style>
.search_block .rs-date-editor {
  width: 100%;
}
.search_block .rs-select {
  width: 100%;
}
</style>
